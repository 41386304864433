import { BaseComponent } from "../types/components";
import React, { createContext, useContext, useEffect, useState } from "react";
import Head from "next/head";
import { useSiteData } from "./SiteDataProvider";

type ConsentContextValue = {
  openModal: () => void;
  // das
};

export const ConsentContext = createContext<ConsentContextValue | null>(null);

declare global {
  interface Window {
    CCM: {
      openWidget: () => void;
      acceptedEmbeddings: Array<{ name: string; id: string }>;
      acceptedCookies: string[];
    };
  }
}
export const ConsentProvider: BaseComponent = ({ children }) => {
  const {
    isProduction,
    currentLanguage,
    cmpId,
    consentBannerLanguage,
    showNewConsent,
    setCookiesAccepted,
  } = useSiteData();
  const providerValue: ConsentContextValue = {
    openModal: () => {
      window.CCM.openWidget();
    },
  };

  let ccmLang = consentBannerLanguage || currentLanguage;
  if (currentLanguage === "rs") ccmLang = "sr";

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("ccm19WidgetClosed", () => {
        const embeddings = window.CCM.acceptedEmbeddings;
        const cookies = window.CCM.acceptedCookies;

        console.log("embeddings", embeddings);
        console.log("cookies", cookies);
        if (cookies?.length) {
          setCookiesAccepted(true);
        } else {
          setCookiesAccepted(false);
        }
      });
    }
  }, []);

  return (
    <ConsentContext.Provider value={providerValue}>
      <Head>
        {(!isProduction || showNewConsent) && cmpId?.length && (
          <script
            src={`https://cloud.ccm19.de/app.js?apiKey=4bb00bd52b1f2cab3111ad535894321e7e623b7084374db0&amp;domain=${cmpId}&amp;lang=${ccmLang}`}
            referrerPolicy="origin"
          ></script>
        )}
      </Head>
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = (): ConsentContextValue => {
  const context = useContext(ConsentContext);

  if (!context) {
    throw new Error("useConsent must be used within ConsentProvider");
  }

  return context;
};
